<template>
    <div
        v-show="!isMobile()"
        :class="{ 'sider-menu-view': true, expand: expand }"
    >
        <n-scrollbar class="scroll">
            <div v-for="item in avaliableMenuData" class="section">
                <div
                    :class="{
                        label: true,
                        'label-active': sectionActive(item),
                    }"
                >
                    <img v-if="item.icon" class="lv1" :src="item.icon" alt="" />
                    <img
                        v-else
                        class="lv1"
                        src="https://finops-public.s3.ap-southeast-1.amazonaws.com/web-images/circle%402x.png"
                        alt=""
                    />
                    <template v-if="expand">{{ item.section }}</template>
                </div>
                <ul class="page-list">
                    <template v-for="page in item.list">
                        <router-link
                            v-if="!!page.path && !!page.pathName"
                            class="link"
                            :to="page.path"
                        >
                            <n-popover
                                :disabled="expand"
                                trigger="hover"
                                placement="right-end"
                            >
                                <template #trigger>
                                    <li :class="{ current: isCurrent(page) }">
                                        <div
                                            :class="{
                                                lv2: true,
                                                expand: !expand,
                                            }"
                                        >
                                            <template
                                                v-if="page.code && !expand"
                                            >
                                                {{ page.code }}
                                            </template>
                                        </div>
                                        <div class="text" v-if="expand">
                                            {{ page.label }}
                                        </div>
                                    </li>
                                </template>
                                {{ page.label }}
                            </n-popover>
                        </router-link>
                        <n-tooltip v-else trigger="hover" placement="right-end">
                            <template #trigger>
                                <li class="disabled">
                                    <div class="link">
                                        <div class="lv2"></div>
                                        <div class="text" v-if="expand">
                                            {{ page.label }}
                                        </div>
                                    </div>
                                </li>
                            </template>
                            开发中，敬请期待
                        </n-tooltip>
                    </template>
                </ul>
            </div>
        </n-scrollbar>
        <n-icon
            :class="{ 'collapse-btn': true, close: !expand }"
            @click="toggleWidth"
        >
            <MenuOpenFilled />
        </n-icon>
    </div>
</template>

<style lang="less">
.sider-menu-view {
    position: relative;
    margin: 8px 10px 13px 8px;
    background-color: @fill-color;
    border-radius: 8px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05),
        0px 8px 10px 0px rgba(0, 0, 0, 0.14),
        0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    overflow-x: hidden;
    transition: width 0.1s ease-in;
    flex-shrink: 0;
    width: 55px;

    .scroll {
        height: calc(100% - 60px);
        padding-top: 8px;
    }

    .section {
        position: relative;

        .label {
            font-size: 14px;
            font-weight: 700;
            padding: 0 16px 0px 18px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            height: 50px;
            border-right: 2px solid @fill-color;
        }

        .label-active {
            border-right: 2px solid @theme-color;
        }

        .lv1 {
            width: 20px;
            height: 20px;
            margin-right: 12px;
            flex-shrink: 0;
        }

        .page-list {
            margin: 0;
            padding-left: 0;
            list-style: none;
            font-size: 14px;

            li {
                height: 38px;
                line-height: 38px;
                padding: 0 18px;
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                .link {
                    display: flex;
                    width: 100%;
                    align-items: center;
                }
                .text {
                    width: 0;
                    color: #333;
                    flex: 1;
                    padding: 0 12px;
                    border-radius: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:hover {
                    background-color: #f4f4f4;
                }
                .lv2 {
                    width: 10px;
                    height: 10px;
                    background: rgba(0, 0, 0, 0.2);
                    border-radius: 50%;
                    display: inline-block;
                    margin: 0 7px 0 4px;
                    flex-shrink: 0;
                    position: relative;
                    font-size: 10px;
                    line-height: 16px;
                    text-align: center;
                    color: transparent;
                    font-weight: bold;

                    transition-property: width, height, color, margin-left;
                    transition-timing-function: ease-in;
                    transition-duration: 0.3s;
                    // transition: width, height, color 0.5s ease-in-out;

                    &.expand {
                        width: 15px;
                        height: 15px;
                        color: #fff;
                        margin-left: 2px;
                    }
                }
            }

            a:link {
                text-decoration: none;
            }

            .current {
                font-weight: bold;
                .text {
                    color: #fff;
                    background: @theme-color;
                }
                .lv2 {
                    background: @theme-color;
                }
            }
            .disabled {
                color: #999;
            }
        }
    }

    .collapse-btn {
        position: absolute;
        bottom: 12px;
        right: 0;
        font-size: 20px;
        color: #626262;
        padding: 12px;
        cursor: pointer;
    }

    .close {
        transform: rotateZ(180deg);
    }

    .collapse-btn-fixed {
        position: fixed;

        width: 40px;
        height: 50px;
        padding: 0 5px;
        border-radius: 25px;
        top: 92%;
        left: -25px;
        z-index: 10;

        background-color: #ddd;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        opacity: 0.4;

        &:hover {
            box-shadow: 1px 1px 4px #999;
            opacity: 1;
        }
    }
}
.expand {
    width: 236px;
}
</style>

<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

import { useUserStore, useGlobalStore } from '@/stores/global';

import { NIcon, NTooltip, NPopover, NScrollbar } from 'naive-ui';
import { MenuOpenFilled } from '@vicons/material';
import { isMobile } from '@/common/tools';

const localMenuData = require('./menu.json');

const route = useRoute();

const store = useUserStore();

const globalStore = useGlobalStore();

let expand = ref(true);

function toggleWidth() {
    expand.value = !expand.value;
}

function hasSameRoleName(roles1, roles2) {
    let roles1Set = new Set(roles1);
    for (let i = 0; i < roles2.length; i++) {
        if (roles1Set.has(roles2[i])) {
            return true;
        }
    }
    return false;
}

function isCurrent(page) {
    let name = route.name;
    if (route.name === page.pathName) {
        return true;
    }
    let params = { ...route.params, ...route.query };
    for (let key in params) {
        if (name.replace(`{${key}}`, params[key]) === page.pathName) {
            return true;
        }
    }
    return false;
}

function getCurrentUserAvaliableMenu(currentRoles, menuData) {
    let avaliableMenu = [];
    menuData.forEach(sectionItem => {
        if (
            sectionItem.roles &&
            !hasSameRoleName(currentRoles, sectionItem.roles)
        ) {
            return;
        }
        avaliableMenu.push({
            section: sectionItem.section,
            icon: sectionItem.icon,
            list: sectionItem.list.filter(item => {
                if (!item.roles) {
                    return true;
                }
                return hasSameRoleName(currentRoles, item.roles);
            }),
        });
    });
    return avaliableMenu;
}

let avaliableMenuData = computed(() =>
    getCurrentUserAvaliableMenu(
        store.currentUser.roles,
        // 如果系统配置里存在目录配置数据，则优先使用，否则使用本地存储的数据
        globalStore.menuSettings || localMenuData
    )
);

function sectionActive(section) {
    return section.list.some(page => isCurrent(page));
}
</script>
