<template>
    <div class="m-container" :style="{ height: contentHeight + 'px' }">
        <SiderMenu v-if="!isIframe"></SiderMenu>
        <div class="content" :style="{ height: contentHeight + 'px' }">
            <n-watermark
                :content="store.currentUser.username"
                selectable
                :font-size="20"
                :width="200"
                :height="200"
                :rotate="-15"
                :y-offset="50"
                font-color="rgba(220, 220, 220, .2)"
            >
                <router-view></router-view>
            </n-watermark>
        </div>
    </div>
</template>

<style lang="less" scoped>
.m-container {
    display: flex;
    flex-direction: row;
    .content {
        flex-grow: 1;
        width: auto;
        overflow: auto;
    }
}
</style>

<script setup>
import { ref, onMounted, computed } from 'vue';

import { useRoute } from 'vue-router';

import { NWatermark } from 'naive-ui';

import { useUserStore } from '@/stores/global';

import SiderMenu from '@/components/SiderMenu/Index';

const store = useUserStore();

const route = useRoute();

let contentHeight = ref(0);

let isIframe = computed(() => route.query.iframe === '1');

function calculateContentHeight() {
    contentHeight.value =
        document.body.clientHeight - (!isIframe.value ? 51 : 0);
}

onMounted(() => {
    calculateContentHeight();
});

window.addEventListener('resize', () => {
    calculateContentHeight();
});
</script>
